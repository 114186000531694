.chats .myMessage {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.chats .otherMessage {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.chats .myMessage,
.chats .otherMessage {
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  line-height: 40px;
}

.chats .tab-pane {
  overflow-y: auto;
  height: 50vh;
  padding: 20px;
}

.chats .chat-item i { color: grey; font-size: 10px; }

.chats .trashIcon { float: right; margin-top: 15px; }

.chat { margin: 10px; padding: 15px 15px 0 15px; border: 1px solid rgba(0,0,0,.125); border-radius: .25rem; }
.chat:hover { background-color: rgba(0,0,0,.05); }

.chat img,
.chats .userInfo img { height: 60px; width: 60px; margin-right: 20px; border-radius: 20%; }
