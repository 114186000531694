#myDogs .card .card-title {
  padding: 0px;
}
#myDogs .card .nav-link {
  padding: 0px;
}
#myDogs .myDogsContainer {
  display: block;
}

#addMyDogButton {
  text-align: center;
}

#dogBreedings tr td {
  cursor: pointer;
}

/* DOG BREEDINGS */
#dogBreeding .breedingPhotos {
  display: inline-block;
}
#dogBreeding .addImageButton {
  text-align: center;
  cursor: pointer;
  color: #007bff;
  display: inline-block;
}
#dogBreeding .addImageButton img {
  height: 130px;
}
#dogBreeding .card-body {
  padding: 1.5rem;
}
#dogBreeding .dogBreedingPhoto,
#dogBreeding .addImageButton {
  height: 210px;
}
#dogBreeding .dogBreedingPhoto .photoActions {
  position: absolute;
  top: 10px;
  right: 0px;
  display: none;
}
#dogBreeding .dogBreedingPhoto:hover .photoActions {
  position: absolute;
  top: 10px;
  right: 0px;
  display: block;
}

@media (max-width: 600px) {
  #dogBreeding .dogBreedingPhoto,
  #dogBreeding .addImageButton {
    width: 100%;
    height: auto;
  }
}

#litters tr td {
  cursor: pointer;
}

/* PUPPIES */
#puppy .puppyPhotos {
  display: inline-block;
}
#puppy .addImageButton {
  text-align: center;
  cursor: pointer;
  color: #007bff;
  display: inline-block;
}
#puppy .addImageButton img {
  height: 130px;
}
#puppy .card-body {
  padding: 1.5rem;
}
#puppy .puppyPhoto,
#puppy .addImageButton {
  height: 210px;
}
#puppy .puppyPhoto .photoActions {
  position: absolute;
  top: 10px;
  right: 0px;
  display: none;
}
#puppy .puppyPhoto:hover .photoActions {
  position: absolute;
  top: 10px;
  right: 0px;
  display: block;
}

@media (max-width: 600px) {
  #puppy .puppyPhoto,
  #puppy .addImageButton {
    width: 100%;
    height: auto;
  }
}

#puppies tr td {
  cursor: pointer;
}
