#kennels .card .card-title {
  padding: 0px;
}
#kennels .card .nav-link {
  padding: 0px;
}
#kennels .actionButtons {
  position: absolute;
  top: 10px;
  right: 0px;
}
#kennels .card {
  height: 370px;
}

#kennel .carousel img {
  height: 600px;
  width: auto;
  object-fit: cover;
}
#kennel .carousel-control-next-icon,
#kennel .carousel-control-prev-icon {
  background-color: #007bff;
}
#kennel .carousel .carousel-item {
  text-align: center;
}

#kennel .dogsContainer {
  display: block;
}
#kennel .card .card-title { padding: 0px; }
#kennel .card .nav-link { padding: 0px; }

#kennel .kennelMainInfo img { width: 100%; }

.breedingAnnouncement .dogsNames { text-align: center; }


@media (max-width: 1000px) { 
  #kennel .carousel img {
    width: inherit;
    height: auto;
  }
 }

