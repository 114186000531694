table.clickable tr td {
  cursor: pointer;
}

input[type="number"] {
  width: 80px;
}

.defaultCursor {
  cursor: default !important;
}

.one {
  background: white;
  padding: 20px 20px;
  text-align: center;
}
.one h1 {
  font-family: 'Righteous', cursive;
  position: relative;
  color: #3CA1D9; 
  display: inline-block;
  border-top: 2px solid;
  border-bottom: 2px solid;
  font-size: 3em;
  padding: 11px 60px;
  margin: 0; 
  line-height: 1;
}
.one h1:before, .one h1:after {
  content: ""; 
  position: absolute;
  top: 0;
  width: 30px;
  height: 100%;
  border-left: 2px solid;
  border-right: 2px solid;
  background: repeating-linear-gradient(180deg, transparent, transparent 2px, #3CA1D9 2px, #3CA1D9 4px);
}
.one h1:before {left: 0;}
.one h1:after {right: 0;}
@media (max-width: 420px) {
  .one h1 {font-size: 2em;}
}

.clickableItem {
  cursor: pointer;
}

.mainLogo {
  width: 60px;
  top: 5px;
  left: 10px;
  position: absolute;
  z-index: 10000;
}

#mainMenu {
  margin-left: 60px;
}

.react-datepicker__input-container input {
  background-color: white;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.globalSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  z-index: 100000;
  display: none;
}

.cart-img-top-placeholder {
  border-style: dotted;
  border-color: gray;
}

.card-title {
  text-transform: uppercase;
}

button.uploadButton {
  padding: 0px;
}
button.uploadButton label {
  margin: 0px;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.blockElement {
  display: block;
}

.table {
  border: 1px solid #dee2e6;
}

.table td,
.table th {
  vertical-align: middle;
}

.icon,
.clickableIcon {
  color: #007bff;
}
.disabledIcon {
  color: gray;
  cursor: default;
}

.clickableIcon {
  cursor: pointer;
}

.photoContainer {
  position: relative;
}
.photoContainer .photoActions {
  position: absolute;
  top: 10px;
  right: 0px;
  display: none;
}
.photoContainer:hover .photoActions {
  position: absolute;
  top: 10px;
  right: 0px;
  display: block;
}

.breedingDetailsTable td {
  background-color: white;
}

.App .card.customCard {
  display: block;
  text-align: center;
  width: 318px;
  float: left;
  height: 390px;
  position: relative;
}
.App .card.customCard img {
  height: 210px;
  width: auto;
  max-width: 316px;
}

.flag-select { padding-bottom: 0px !important; }
.flag-select img { vertical-align: top; margin-top: -3px; }
.flag-select .flag-select__btn .flag-select__option__label { color: rgba(255,255,255,.5); }