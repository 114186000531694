#dogs .card .card-title {
  padding: 0px;
}
#dogs .card .nav-link {
  padding: 0px;
}

#dog #carousel img {
  height: 600px;
  width: auto;
  object-fit: cover;
}

@media (max-width: 600px) { 
  #dog #carousel img {
    width: inherit;
    height: auto;
  }
 }


#dog .carousel-control-next-icon,
#dog .carousel-control-prev-icon {
  background-color: #007bff;
}
#dog #carousel .carousel-item {
  text-align: center;
}

#dogs .actionButtons { position: absolute; top: 10px; right: 0px; }
