#handlers .card .card-title {
  padding: 0px;
}
#handlers .card .nav-link {
  padding: 0px;
}
#handlers .actionButtons {
  position: absolute;
  top: 10px;
  right: 0px;
}

#handler #carousel img {
  height: 600px;
  width: auto;
  object-fit: cover;
}
#handler .carousel-control-next-icon,
#handler .carousel-control-prev-icon {
  background-color: #007bff;
}
#handler #carousel .carousel-item {
  text-align: center;
}
#handlers .card {
  height: 370px;
}
